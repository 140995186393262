import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Greetings from './components/Greetings';
import LoginPage from './components/login/LoginPage';
import Loader from './components/dashboard/Loader/Loader';
import requireAuth from './utils/requireAuth';
import Redirect from './components/common/Redirect';

const LegalAndPoliciesLazy = React.lazy(() =>
  import('./components/dashboard/legalAndPolicies')
);
const UpdateLegalAndPoliciesLazy = React.lazy(() =>
  import('./components/dashboard/legalAndPolicies/UpdateLegalPolicy')
);
const SupplierLazy = React.lazy(() =>
  import('./components/dashboard/supplier/SupplierPage')
);
const SupplierEditLazy = React.lazy(() =>
  import('./components/dashboard/supplier/SupplierEdit')
);
const SupplierAddAttributesLazy = React.lazy(() =>
  import('./components/dashboard/supplier/SupplierAddAttributes')
);
const CatalogLazy = React.lazy(() =>
  import('./components/dashboard/catalog/Catalog')
);
const CatalogProductAddLazy = React.lazy(() =>
  import('./components/dashboard/catalog/products/add/CatalogProductAdd')
);
const CatalogEditLazy = React.lazy(() =>
  import('./components/dashboard/catalog/CatalogEdit')
);
const CatalogInfoEditLazy = React.lazy(() =>
  import('./components/dashboard/catalog/CatalogInfoEdit')
);
const CatalogProductsEditLazy = React.lazy(() =>
  import('./components/dashboard/catalog/products/edit/CatalogProductsEdit')
);
const CatalogSupplierViewLazy = React.lazy(() =>
  import('./components/dashboard/catalog/supplier/CatalogSupplierView')
);
const CatalogAddSupplierProductsLazy = React.lazy(() =>
  import('./components/dashboard/catalog/supplier/CatalogAddSupplierProducts')
);
const CollectionPageLazy = React.lazy(() =>
  import('./components/dashboard/collection/CollectionPage')
);
const CollectionAddEditLazy = React.lazy(() =>
  import('./components/dashboard/collection/CollectionAddEdit')
);
const SingleCollectionViewLazy = React.lazy(() =>
  import('./components/dashboard/collection/SingleCollectionView')
);
const WidgetGroupsViewLazy = React.lazy(() =>
  import('./components/dashboard/widgets/WidgetGroupsView')
);
const WidgetGroupAddEditLazy = React.lazy(() =>
  import('./components/dashboard/widgets/WidgetGroupAddEdit')
);
const LandingPagesViewLazy = React.lazy(() =>
  import('./components/dashboard/widgets/landing-pages/LandingPagesView')
);
const LandingPageAddEditLazy = React.lazy(() =>
  import('./components/dashboard/widgets/landing-pages/LandingPageAddEdit')
);
const OrderLazy = React.lazy(() =>
  import('./components/dashboard/order/OrderTable')
);
const OrderDetailsLazy = React.lazy(() =>
  import('./components/dashboard/order/OrderDetails')
);
const ResellerBonusDetailsLazy = React.lazy(() =>
  import('./components/dashboard/reseller/payment/bonus/ResellerBonusDetails')
);
const ResellerVerificationLazy = React.lazy(() =>
  import('./components/dashboard/reseller/ResellerVerification')
);
const ResellerReferralViewLazy = React.lazy(() =>
  import('./components/dashboard/reseller/ResellerReferralView')
);
const ResellerReferralsViewLazy = React.lazy(() =>
  import('./components/dashboard/reseller/ResellerReferralsView')
);
const ResellerEditViewLazy = React.lazy(() =>
  import('./components/dashboard/reseller/edit/ResellerEditView')
);
const SupplierPaymentFilesLazy = React.lazy(() =>
  import('./components/dashboard/supplier/payment/SupplierPaymentFiles')
);
const CODVerificationFileUploadPageLazy = React.lazy(() =>
  import('./components/dashboard/cod/CODVerificationFileUploadPage')
);
const CODVerificationFilesPageLazy = React.lazy(() =>
  import('./components/dashboard/cod/CODVerificationFilesPage')
);
const CODVerificationFilePageLazy = React.lazy(() =>
  import('./components/dashboard/cod/CODVerificationFilePage')
);
const BulkCancellationFileUploadPageLazy = React.lazy(() =>
  import(
    './components/dashboard/bulkCancellation/BulkCancellationFileUploadPage'
  )
);
const BulkCancellationFilesPageLazy = React.lazy(() =>
  import('./components/dashboard/bulkCancellation/BulkCancellationFilesPage')
);
const BulkCancellationFilePageLazy = React.lazy(() =>
  import('./components/dashboard/bulkCancellation/BulkCancellationFilePage')
);
const BannersViewLazy = React.lazy(() =>
  import('./components/dashboard/banners/BannersView')
);

const BannerAddEditLazy = React.lazy(() =>
  import('./components/dashboard/banners/BannersAddEdit')
);

const FirebaseAdminViewLazy = React.lazy(() =>
  import('./components/dashboard/firebase/FirebaseAdminView')
);
const CreditsWinnerInfographLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/CreditsWinnerInfograph/CreditsWinnerInfograph'
  )
);
const CreditsWinnerInfographFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/CreditsWinnerInfograph/CreditsWinnerInfographFiles'
  )
);
const CreditsWinnerInfographFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/CreditsWinnerInfograph/CreditsWinnerInfographFile'
  )
);
const TaxInvoiceFileUploadPageLazy = React.lazy(() =>
  import('./components/dashboard/taxInvoices/TaxInvoiceFileUploadPage')
);
const TaxInvoiceHomePageLazy = React.lazy(() =>
  import('./components/dashboard/taxInvoices/TaxInvoiceHomePage')
);
const TaxInvoiceFilesPageLazy = React.lazy(() =>
  import('./components/dashboard/taxInvoices/TaxInvoiceFilesPage')
);
const TaxInvoiceFilePageLazy = React.lazy(() =>
  import('./components/dashboard/taxInvoices/TaxInvoiceFilePage')
);
const SupplyTasksLazy = React.lazy(() =>
  import('./components/dashboard/supplyTasks/SupplyTasks')
);
const PriceDeactivationLazy = React.lazy(() =>
  import('./components/dashboard/supplyTasks/PriceDeactivation')
);
const MoveCatalogToCollectionHiddenLazy = React.lazy(() =>
  import('./components/dashboard/supplyTasks/MoveCatalogToCollectionHidden')
);
const MoveCatalogToCollectionQualityHiddenLazy = React.lazy(() =>
  import(
    './components/dashboard/supplyTasks/MoveCatalogToCollectionQualityHidden'
  )
);
const AddCatalogsToCollectionsLazy = React.lazy(() =>
  import('./components/dashboard/supplyTasks/AddCatalogsToCollections')
);
const RemoveCatalogsFromCollectionsLazy = React.lazy(() =>
  import('./components/dashboard/supplyTasks/RemoveCatalogsFromCollections')
);
const SuborderSLAUpdateLazy = React.lazy(() =>
  import('./components/dashboard/supplyTasks/SuborderSLAUpdate')
);
const SubOrderCancelDateUpdateLazy = React.lazy(() =>
  import('./components/dashboard/supplyTasks/SubOrderCancelDateUpdate')
);
const ReviewRatingsLazy = React.lazy(() =>
  import('./components/dashboard/reviewRatings/ReviewRatings')
);
const ReviewRatingsVideosLazy = React.lazy(() =>
  import('./components/dashboard/reviewRatings/ReviewRatingsVideos')
);
const ResellerTieringLazy = React.lazy(() =>
  import('./components/dashboard/resellerTiering/ResellerTiering')
);
const ResellerTieringFilesLazy = React.lazy(() =>
  import('./components/dashboard/resellerTiering/ResellerTieringFiles')
);
const ResellerTieringFileLazy = React.lazy(() =>
  import('./components/dashboard/resellerTiering/ResellerTieringFile')
);

const MentorDashboardLazy = React.lazy(() =>
  import('./components/dashboard/mentorDashboard/MentorDashboard')
);
const MentorDashboardFilesLazy = React.lazy(() =>
  import('./components/dashboard/mentorDashboard/MentorDashboardFiles')
);
const MentorDashboardFileLazy = React.lazy(() =>
  import('./components/dashboard/mentorDashboard/MentorDashboardFile')
);
const AdminAccessViewLazy = React.lazy(() =>
  import('./components/dashboard/admin/AdminAccessView')
);
const CatalogRatingRewardsViewLazy = React.lazy(() =>
  import('./components/dashboard/catalog-rating/CatalogRatingRewardsView')
);
const CatalogRatingRewardsFilesLazy = React.lazy(() =>
  import('./components/dashboard/catalog-rating/CatalogRatingRewardsFiles')
);
const CatalogRatingRewardsFileLazy = React.lazy(() =>
  import('./components/dashboard/catalog-rating/CatalogRatingRewardsFile')
);
const SupplierManifestViewLazy = React.lazy(() =>
  import('./components/dashboard/supplyTasks/SupplierManifestView')
);
const ReverseTinderFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/reverseTinder/ReverseTinderFileUpload'
  )
);
const ReverseTinderFilesLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/reverseTinder/ReverseTinderFiles')
);
const ReverseTinderFileLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/reverseTinder/ReverseTinderFile')
);
const AdminFilesUploadingViewLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/AdminFilesUploadingView')
);
const ChinaVSKUFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/chinavskuupload/ChinaVSKUFileUpload'
  )
);
const ChinaVSKUUploadedFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/chinavskuupload/ChinaVSKUUploadedFiles'
  )
);
const ChinaVSKUUploadedFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/chinavskuupload/ChinaVSKUUploadedFile'
  )
);
const CatalogVideosFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/catalogVideos/CatalogVideosFileUpload'
  )
);
const CatalogVideosFilesLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/catalogVideos/CatalogVideosFiles')
);
const CatalogVideosFileLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/catalogVideos/CatalogVideosFile')
);
const CatalogVideosFetchViewLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/catalogVideos/CatalogVideosFetchView'
  )
);
const PriceExperimentViewLazy = React.lazy(() =>
  import('./components/dashboard/priceExperiment/PriceExperimentView')
);
const PriceExperimentAddEditLazy = React.lazy(() =>
  import('./components/dashboard/priceExperiment/PriceExperimentAddEdit')
);
const AudienceSystemLazy = React.lazy(() =>
  import('./components/dashboard/audienceSystem/AudienceSystem')
);
const MarketingDashboardLazy = React.lazy(() =>
  import('./components/dashboard/marketingDashboard/MarketingDashboard')
);
const BitlyGeneratorLazy = React.lazy(() =>
  import('./components/dashboard/marketingDashboard/BitlyGenerator')
);
const UrlShortenerLazy = React.lazy(() =>
  import('./components/dashboard/marketingDashboard/UrlShortener')
);
const CustomUrlShortenerLazy = React.lazy(() =>
  import('./components/dashboard/marketingDashboard/CustomUrlShortener')
);
const FileUploaderLazy = React.lazy(() =>
  import('./components/dashboard/fileUploader/FileUploader')
);
const TaxonomyProductFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/taxonomyProductData/TaxonomyProductFileUpload'
  )
);
const TaxonomyProductFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/taxonomyProductData/TaxonomyProductFiles'
  )
);
const TaxonomyProductFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/taxonomyProductData/TaxonomyProductFile'
  )
);
const VisualSearchLazy = React.lazy(() =>
  import('./components/dashboard/VisualSearch/VisualSearch')
);
const SearchOpsLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/SearchOps')
);
const SearchReviewLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/SubViews/SearchReview')
);
const ProductsDeleteFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/productsDelete/ProductsDeleteFileUpload'
  )
);
const ProductsDeleteFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/productsDelete/ProductsDeleteFiles'
  )
);
const ProductsDeleteFileLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/productsDelete/ProductsDeleteFile')
);
const AudienceAddEditManualLazy = React.lazy(() =>
  import('./components/dashboard/audienceSystem/AudienceAddEditManual')
);
const AudienceAddEditAutomatedLazy = React.lazy(() =>
  import('./components/dashboard/audienceSystem/AudienceAddEditAutomated')
);
const SupplierPenaltyFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/supplierPenaltyFileUpload/SupplierPenaltyFileUpload'
  )
);
const SupplierPenaltyFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/supplierPenaltyFileUpload/SupplierPenaltyFiles'
  )
);
const SupplierPenaltyFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/supplierPenaltyFileUpload/SupplierPenaltyFile'
  )
);
const SupplierPenaltyWaiverFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/supplierPenaltyWaiverFileUpload/SupplierPenaltyWaiverFileUpload'
  )
);
const SupplierPenaltyWaiverFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/supplierPenaltyWaiverFileUpload/SupplierPenaltyWaiverFiles'
  )
);
const SupplierPenaltyWaiverFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/supplierPenaltyWaiverFileUpload/SupplierPenaltyWaiverFile'
  )
);
const SupplierPenaltyTypeFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/supplierPenaltyTypeFileUpload/SupplierPenaltyTypeFileUpload'
  )
);
const SupplierPenaltyTypeFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/supplierPenaltyTypeFileUpload/SupplierPenaltyTypeFiles'
  )
);
const SupplierPenaltyTypeFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/supplierPenaltyTypeFileUpload/SupplierPenaltyTypeFile'
  )
);
const OrderShippedDeliveredUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/orderShippedDelivered/OrderShippedDeliveredUpload'
  )
);
const OrderShippedDeliveredFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/orderShippedDelivered/OrderShippedDeliveredFiles'
  )
);
const OrderShippedDeliveredFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/orderShippedDelivered/OrderShippedDeliveredFile'
  )
);
const UserCODFileUploadLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/userCOD/UserCODFileUpload')
);
const UserCODFileLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/userCOD/UserCODUploadedFile')
);
const UserCODUploadedFilesLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/userCOD/UserCODUploadedFiles')
);
const InternationalSupplierPaymentFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/supplier/international-payment/InternationalSupplierPaymentFiles'
  )
);
const AppBannerFileUploadLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/appBanner/AppBannerFileUpload')
);
const AppBannerFilesLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/appBanner/AppBannerFiles')
);
const AppBannerFileLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/appBanner/AppBannerFile')
);
const SmsPortalLazy = React.lazy(() =>
  import('./components/dashboard/smsPortal')
);
const SmsPortalCreateTemplateLazy = React.lazy(() =>
  import('./components/dashboard/smsPortal/subviews/CreateTemplate')
);
const SmsPortalSendSmsLazy = React.lazy(() =>
  import('./components/dashboard/smsPortal/subviews/SendSms')
);
const SmsPortalMakeCallsLazy = React.lazy(() =>
  import('./components/dashboard/smsPortal/subviews/MakeCalls')
);
const NotificationPortalLazy = React.lazy(() =>
  import('./components/dashboard/notificationPortal')
);
const CreateTemplateLazy = React.lazy(() =>
  import(
    './components/dashboard/notificationPortal/CreateTemplate/CreateTemplate'
  )
);
const SendNotificationsLazy = React.lazy(() =>
  import(
    './components/dashboard/notificationPortal/SendNotifications/SendNotifications'
  )
);
const WhatsAppNotificationsLazy = React.lazy(() =>
  import('./components/dashboard/whatsAppNotifications')
);
const WhatsAppCreateTemplateLazy = React.lazy(() =>
  import(
    './components/dashboard/whatsAppNotifications/WhatsAppCreateTemplate/WhatsAppCreateTemplate'
  )
);
const WhatsAppSendNotificationsLazy = React.lazy(() =>
  import(
    './components/dashboard/whatsAppNotifications/WhatsAppSendNotifications/WhatsAppSendNotifications'
  )
);
const AllNotificationCampaignsLazy = React.lazy(() =>
  import('./components/dashboard/allNotificationCampigns')
);

const ReferralCampaignLazy = React.lazy(() =>
  import('./components/dashboard/referralCampaign')
);
const CreateCampaignLazy = React.lazy(() =>
  import('./components/dashboard/referralCampaign/createCampaign')
);
const RewardCampaignLazy = React.lazy(() =>
  import('./components/dashboard/referralCampaign/rewards')
);
const AllCampaignsLazy = React.lazy(() =>
  import('./components/dashboard/referralCampaign/allCampaigns')
);
const ReferralProgramLazy = React.lazy(() =>
  import('./components/dashboard/referralProgram')
);
const CreateReferralPlanLazy = React.lazy(() =>
  import('./components/dashboard/referralProgram/createReferralPlan')
);
const AllReferralPlansLazy = React.lazy(() =>
  import('./components/dashboard/referralProgram/allReferralPlans')
);
const BadgeGroupLazy = React.lazy(() =>
  import('./components/dashboard/referralProgram/badgeGroup')
);
const AllBadgeGroupsLazy = React.lazy(() =>
  import('./components/dashboard/referralProgram/allBadgeGroups')
);
const ReferralAudienceSystemLazy = React.lazy(() =>
  import('./components/dashboard/referralProgram/audienceSystem')
);
const ReferralAudienceCreateLazy = React.lazy(() =>
  import('./components/dashboard/referralProgram/audienceSystem/createAudience')
);
const ReferralAudienceLazy = React.lazy(() =>
  import('./components/dashboard/referralProgram/audienceSystem/audience')
);
const AudienceBadgeMapLazy = React.lazy(() =>
  import(
    './components/dashboard/referralProgram/audienceSystem/audienceBadgeMap'
  )
);
const AllAudienceBadgeMapLazy = React.lazy(() =>
  import(
    './components/dashboard/referralProgram/audienceSystem/allAudienceBadgeMap'
  )
);
const BulkBadgeGroupUpdateLazy = React.lazy(() =>
  import(
    './components/dashboard/referralProgram/audienceSystem/bulkBadgeGroupUpdate'
  )
);
const ReferralApprovalsLazy = React.lazy(() =>
  import('./components/dashboard/referralProgram/referralApproval')
);

const UserBlockFileUploadLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/userBlock/UserBlockFileUpload')
);
const UserBlockUploadedFilesLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/userBlock/UserBlockUploadedFiles')
);
const UserBlockUploadedFileLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/userBlock/UserBlockUploadedFile')
);

const VideoInfluencerLazy = React.lazy(() =>
  import('./components/dashboard/videoInfluencer/videoInfluencerReview')
);
const SubOrderCarrierChangeFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/subOrderCarrierChangeFileUpload/SubOrderCarrierChangeFileUpload'
  )
);
const SubOrderCarrierChangeFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/subOrderCarrierChangeFileUpload/SubOrderCarrierChangeFiles'
  )
);
const SubOrderCarrierChangeFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/subOrderCarrierChangeFileUpload/SubOrderCarrierChangeFile'
  )
);

const ExcelUploadContainerLazy = React.lazy(() =>
  import('./components/excelUpload/container/ExcelUploadContainer')
);
const ExcelUploadedFilesContainerLazy = React.lazy(() =>
  import('./components/excelUpload/container/ExcelUploadedFilesContainer')
);
const DynamicWebViewLazy = React.lazy(() =>
  import('./components/dashboard/dynamicWebview')
);
const DynamicWebViewNewPageLazy = React.lazy(() =>
  import('./components/dashboard/dynamicWebview/components/webviewEditor')
);
const ImageABFileUploadLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/imageAB/ImageABFileUpload')
);
const ImageABUploadedFileLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/imageAB/ImageABUploadedFile')
);
const ImageABUploadedFilesLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/imageAB/ImageABUploadedFiles')
);
const BulkOrderFileUploadLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/bulkOrder/BulkOrderFileUpload')
);
const BulkOrderUploadedFilesLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/bulkOrder/BulkOrderUploadedFiles')
);
const BulkOrderUploadedFileLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/bulkOrder/BulkOrderUploadedFile')
);
const RuleEngineLazy = React.lazy(() =>
  import('./components/dashboard/ruleEngine/RuleEngine')
);
const RuleEngineCreateLazy = React.lazy(() =>
  import('./components/dashboard/ruleEngine/RuleEngineCreate')
);
const BulkCollectionFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/bulkCollection/BulkCollectionFileUpload'
  )
);
const BulkCollectionUploadedFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/bulkCollection/BulkCollectionUploadedFile'
  )
);
const BulkCollectionUploadedFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/bulkCollection/BulkCollectionUploadedFiles'
  )
);
const OrderShipFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/orderShipFileUploading/OrderShipFileUpload'
  )
);
const OrderShipFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/orderShipFileUploading/OrderShipFiles'
  )
);
const OrderShipFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/orderShipFileUploading/OrderShipFile'
  )
);
const OrderStatusFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/orderStatusFileUploading/OrderStatusFileUpload'
  )
);
const OrderStatusFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/orderStatusFileUploading/OrderStatusFiles'
  )
);
const OrderStatusFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/orderStatusFileUploading/OrderStatusFile'
  )
);
const CategoryTreesLazy = React.lazy(() =>
  import('./components/dashboard/CategoryNavigation/CategoryTrees')
);
const CategoryTreesEditorLazy = React.lazy(() =>
  import('./components/dashboard/CategoryNavigation/CategoryTreeEditor')
);
const CLPAdminLazy = React.lazy(() =>
  import('./components/dashboard/CLPAdmin')
);
const TopNavAdminLazy = React.lazy(() =>
  import('./components/dashboard/TopNavAdmin')
);
const SortnFilterLazy = React.lazy(() =>
  import('./components/dashboard/SortnFilter')
);
const IntentDetectionRulesLazy = React.lazy(() =>
  import(
    './components/dashboard/SearchOps/intentDetectionRules/IntentDetectionRules'
  )
);
const AddIntentDetectionRuleLazy = React.lazy(() =>
  import(
    './components/dashboard/SearchOps/intentDetectionRules/AddIntentDetectionRule'
  )
);
// --> will be removed after AB experiment
const IntentDetectionRulesV2Lazy = React.lazy(() =>
  import(
    './components/dashboard/SearchOps/intentDetectionRulesV2/IntentDetectionRules'
  )
);
const AddIntentDetectionRuleV2Lazy = React.lazy(() =>
  import(
    './components/dashboard/SearchOps/intentDetectionRulesV2/AddIntentDetectionRule'
  )
);
// end-->
const QueryNormalizationLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/queryNormalization')
);
const AddEditQueryNormalizationLazy = React.lazy(() =>
  import(
    './components/dashboard/SearchOps/queryNormalization/AddEditQueryNormalization'
  )
);
const QueryNormalizationFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/queryNormalizationUpload/QueryNormalizationFileUpload'
  )
);
const CommunityModerationPageLazy = React.lazy(() =>
  import('./components/dashboard/communityModeration/communityModerationPage')
);
const POWProductFileUploadLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/pow_products/POWProductFileUpload')
);
const POWProductUploadedFilesLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/pow_products/POWProductUploadedFiles'
  )
);
const POWProductUploadedFileLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/pow_products/POWProductUploadedFile'
  )
);
const CLPProductsViewLazy = React.lazy(() =>
  import('./components/dashboard/CLPAdmin/CLPProductsView')
);

const UATCatalogsLazy = React.lazy(() =>
  import('./components/dashboard/supply-tasks/UATCatalogs')
);

const CommunityUploadPageLazy = React.lazy(() =>
  import('./components/dashboard/communityUpload/communityUploadPage')
);
const HVFAdminPageLazy = React.lazy(() =>
  import('./components/dashboard/HVFAdmin/HVFAdmin')
);
const AddNewFilterGroupLazy = React.lazy(() =>
  import('./components/dashboard/HVFAdmin/addNewFilterGroup')
);
const EditFilterGroupLazy = React.lazy(() =>
  import('./components/dashboard/HVFAdmin/editFilterGroup')
);
const SupplierFinanceLazy = React.lazy(() =>
  import('./components/dashboard/supplierFinance/SupplierFinance')
);
const FulfilmentLazy = React.lazy(() =>
  import('./components/dashboard/fulfilment')
);
const ImagesBulkUploadLazy = React.lazy(() =>
  import('./components/dashboard/filesUpload/imagesBulkUpload/ImagesBulkUpload')
);
const QueryExpansionLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/queryExpansion')
);
const QueryTaggingLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/queryTagging/QueryTagging')
);
const QueryTaggingBlacklistLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/queryTagging/blacklist/Blacklist')
);
const AddEditQueryTaggingLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/queryTagging/AddEditQueryTagging')
);
const AddEditBlacklistLazy = React.lazy(() =>
  import(
    './components/dashboard/SearchOps/queryTagging/blacklist/AddEditBlacklist'
  )
);
const QueryTaggingFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/queryTaggingUpload/queryTaggingUpload'
  )
);
const QueryTaggingBlacklistFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/queryTaggingUpload/queryTaggingBlacklistUpload'
  )
);
const QclCorrectionLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/qclCorrection')
);
const ServiceabilityFileUploadLazy = React.lazy(() =>
  import(
    './components/dashboard/filesUpload/serviceabilityFileUpload/ServiceabilityFileUpload'
  )
);
const AdsTaskLazy = React.lazy(() =>
  import('./components/dashboard/adsTask/AdsTask')
);
const AdsTaskFileUploadLazy = React.lazy(() =>
  import('./components/dashboard/adsTask/AdsTaskFileUpload')
);
const AdminNoticesLazy = React.lazy(() =>
  import('./components/dashboard/adminNotices/AdminNotices')
);
const SearchRuleEngineLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/searchRuleEngine')
);
const AddSearchRuleLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/searchRuleEngine/AddSearchRule')
);
const StopWordsLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/stopWords')
);

const TaskCampaignLazy = React.lazy(() =>
  import('./components/dashboard/adsTask/TaskCamapaign')
);

const MMAConsentBannerCreation = React.lazy(() =>
  import('./components/dashboard/MMMLink/MMAConsentBannerCreation')
);

const MMALinkLazy = React.lazy(() => import('./components/dashboard/MMMLink'));

const TaskStatusLazy = React.lazy(() =>
  import('./components/dashboard/adsTask/TaskStatus')
);

const LiveStreamLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/LiveStream')
);

const LiveStreamsLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/LiveStreams')
);

const CreateLiveStreamLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/CreateLiveStream')
);

const LiveStreamBulkStreamCreationLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/LiveStreams/BulkStreamCreation')
);

const LiveStreamBulkStreamUpdationLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/LiveStreams/BulkStreamUpdation')
);

const CreateTemplatePresetLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/CreateTemplatePreset')
);

const CreateCampaignPresetLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/CreateCampaignPreset')
);

const LiveCommerceLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/LiveCommerce')
);

const TemplatePresetsLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/TemplatePresets')
);

const CampaignPresetsLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/CampaignPresets')
);

const CreateRealEstatePresetLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/CreateRealEstatePreset')
);

const RealEstatePresetsLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/RealEstatePresets')
);

const UpsertBannersLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/Banners/UpsertBanners')
);

const BannersLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/Banners/Banners')
);

const DiscountsLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/Discounts/Discounts')
);

const AddDiscountLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/Discounts/AddDiscount')
);

const UploadDiscountLazy = React.lazy(() =>
  import('./components/dashboard/liveCommerce/Discounts/UploadDiscount')
);

const ABGMappingLazy = React.lazy(() =>
  import('./components/dashboard/SearchOps/abgMapping')
);

const CampaignDashboard = React.lazy(() =>
  import('./components/dashboard/DisplayAds/CampaignDashboard')
);

const DisplayAwareness = React.lazy(() =>
  import('./components/dashboard/DisplayAds/DisplayAwareness')
);

const CreateNewCampaign = React.lazy(() =>
  import('./components/dashboard/DisplayAds/CreateNewCampaign')
);

const NonSellerPortal = React.lazy(() =>
  import('./components/dashboard/NonSellerPortal/SignUp')
);

const POWWhatsAppDashboard = React.lazy(() => import('./components/dashboard/pow/POWWhatsAppDashboard'));

export default (
  <React.Suspense fallback={<Loader />}>
    <Routes>
      <Route path="/" element={requireAuth(Greetings, [''], true)} />
      <Route path="/login" element={requireAuth(LoginPage, [''], true)} />
      <Route
        path="/suppliers"
        element={requireAuth(SupplierLazy, ['suppliers_view'])}
      />
      <Route
        path="/pow-whatsapp-dashboard"
        element={requireAuth(POWWhatsAppDashboard, ['pow_whatsapp_agent_dashboard'])}
      />
      <Route
        path="/supplier/add-supplier"
        element={requireAuth(SupplierEditLazy, [
          'supplier_add',
          'suppliers_view',
        ])}
      />
      <Route
        path="/supplier/edit/:id"
        element={requireAuth(SupplierEditLazy, ['supplier_detailed_view', 'supplier_edit'])}
      />
      <Route
        path="/catalogs"
        element={requireAuth(CatalogLazy, ['catalogs_view'])}
      />
      <Route
        path="/catalog/add-catalog"
        element={requireAuth(CatalogInfoEditLazy, [
          'catalog_add',
          'catalog_view',
          'catalog_edit',
        ])}
      />
      <Route
        path="/catalog/edit/:id"
        element={requireAuth(CatalogEditLazy, [
          'catalog_view',
          'catalog_edit',
          'catalog_add',
        ])}
      />
      <Route
        path="/catalog/edit/:id/edit_catalog"
        element={requireAuth(CatalogInfoEditLazy, [
          'catalog_view',
          'catalog_edit',
          'catalog_add',
        ])}
      />
      <Route
        path="/catalog/:id/product-upload"
        element={requireAuth(CatalogProductAddLazy, ['catalog_product_add'])}
      />
      <Route
        path="/catalog/:id/products/edit"
        element={requireAuth(CatalogProductsEditLazy, [
          'catalog_product_view',
          'catalog_product_edit',
        ])}
      />
      <Route
        path="/catalog/edit/:id/add_new_supplier"
        element={requireAuth(CatalogSupplierViewLazy, [
          'catalog_supplier_view',
          'product_supplier_mapping_add',
        ])}
        isAddSupplier={true}
      />
      <Route
        path="/catalog/edit/:id/edit_supplier"
        element={requireAuth(CatalogSupplierViewLazy, [
          'catalog_supplier_view',
          'product_supplier_mapping_edit',
        ])}
        isAddSupplier={false}
      />
      <Route
        path="catalog/edit/:id/add_new_supplier/:supplier_id/products"
        element={requireAuth(CatalogAddSupplierProductsLazy, [
          'catalog_supplier_view',
          'product_supplier_mapping_add',
        ])}
        isAddSupplier={true}
      />
      <Route
        path="catalog/edit/:id/edit_supplier/:supplier_id/products"
        element={requireAuth(CatalogAddSupplierProductsLazy, [
          'catalog_supplier_view',
          'product_supplier_mapping_edit',
        ])}
        isAddSupplier={false}
      />
      <Route
        path="/collections"
        element={requireAuth(
          CollectionPageLazy,
          'collections_view',
          'collection_view'
        )}
      />
      <Route
        path="/collection/add-collection"
        element={requireAuth(CollectionAddEditLazy, [
          'collection_view',
          'collection_add',
        ])}
      />
      <Route
        path="/collection/:id"
        element={requireAuth(SingleCollectionViewLazy, ['collection_view'])}
      />
      <Route
        path="/collection/edit/:id"
        element={requireAuth(CollectionAddEditLazy, [
          'collection_view',
          'collection_edit',
        ])}
      />
      <Route
        path="/widgets-group"
        element={requireAuth(WidgetGroupsViewLazy, ['widgets_view'])}
      />
      <Route
        path="/widget-group/add"
        element={requireAuth(WidgetGroupAddEditLazy, ['widgets_add'])}
      />
      <Route
        path="/widget-group/:id"
        element={requireAuth(WidgetGroupAddEditLazy, [
          'widgets_view',
          'widgets_edit',
        ])}
      />
      <Route
        path="/landing-pages"
        element={requireAuth(LandingPagesViewLazy, ['landing_pages_view'])}
      />
      <Route
        path="/landing-page/add"
        element={requireAuth(LandingPageAddEditLazy, ['landing_page_add'])}
      />
      <Route
        path="/landing-page/:id"
        element={requireAuth(LandingPageAddEditLazy, [
          'landing_pages_view',
          'landing_page_edit',
        ])}
      />
      <Route
        path="/orders"
        element={requireAuth(OrderLazy, ['order_view', 'orders_view'])}
      />
      <Route
        path="/orders/:page_number"
        element={requireAuth(OrderLazy, ['order_view', 'orders_view'])}
      />
      <Route
        path="/order/:id"
        element={requireAuth(OrderDetailsLazy, ['order_view'])}
      />
      <Route
        path="/reseller/payment/bonus"
        element={requireAuth(ResellerBonusDetailsLazy, [
          'reseller_bonus_details',
        ])}
      />
      <Route
        path="/reseller/verification"
        element={requireAuth(ResellerVerificationLazy, [
          'reseller_verification_view',
        ])}
      />
      <Route
        path="/reseller/referral"
        element={requireAuth(ResellerReferralViewLazy, [
          'reseller_referrals_view',
        ])}
      />
      <Route
        path="/reseller/referrals"
        element={requireAuth(ResellerReferralsViewLazy, [
          'reseller_referrals_view',
        ])}
      />
      <Route
        path="/reseller/edit"
        element={requireAuth(ResellerEditViewLazy, ['reseller_edit'])}
      />
      <Route
        path="/reseller/credits/upload/files"
        element={requireAuth(()=><Redirect path='/v3/reseller/credits-upload/files'/>, [
          'reseller_credits_file_upload',
        ])}
      />
      <Route
        path="/reseller/credits/upload"
        element={requireAuth(()=><Redirect path='/v3/reseller/credits-upload'/>, [
          'reseller_credits_file_upload',
        ])}
      />
      <Route
        path="/codverification"
        element={requireAuth(CODVerificationFileUploadPageLazy, [
          'cod_verification_view',
        ])}
      />
      <Route
        path="/codverification/files"
        element={requireAuth(CODVerificationFilesPageLazy, [
          'cod_verification_uploaded_files_view',
        ])}
      />
      <Route
        path="/codverification/file/:id"
        element={requireAuth(CODVerificationFilePageLazy, [
          'cod_verification_uploaded_files_view',
        ])}
      />
      <Route
        path="/bulkcancellation"
        element={requireAuth(BulkCancellationFileUploadPageLazy, [
          'bulk_cancellations_view',
        ])}
      />
      <Route
        path="/bulkcancellation/files"
        element={requireAuth(BulkCancellationFilesPageLazy, [
          'bulk_cancellations_upload_files',
        ])}
      />
      <Route
        path="/bulkcancellation/file/:id"
        element={requireAuth(BulkCancellationFilePageLazy, [
          'bulk_cancellations_upload_files',
        ])}
      />
      <Route
        path="/credits-winner-infograph"
        element={requireAuth(CreditsWinnerInfographLazy, [
          'credits_winner_infograph',
        ])}
      />
      <Route
        path="/credits-winner-infograph/files"
        element={requireAuth(CreditsWinnerInfographFilesLazy, [
          'credits_winner_infograph',
        ])}
      />
      <Route
        path="/credits-winner-infograph/file/:id"
        element={requireAuth(CreditsWinnerInfographFileLazy, [
          'credits_winner_infograph',
        ])}
      />
      <Route
        path="/tax-invoices"
        element={requireAuth(TaxInvoiceHomePageLazy, [
          'supplier_invoice',
        ])}
      />
      <Route
        path="/tax-invoices/:gstType"
        element={requireAuth(TaxInvoiceFileUploadPageLazy, [
          'supplier_invoice',
        ])}
      />
      <Route
        path="/tax-invoices/files"
        element={requireAuth(TaxInvoiceFilesPageLazy, ['supplier_invoice'])}
      />
      <Route
        path="/tax-invoices/file/:id"
        element={requireAuth(TaxInvoiceFilePageLazy, ['supplier_invoice'])}
      />
      <Route
        path="/supplier/payment"
        element={requireAuth(SupplierPaymentFilesLazy, [
          'supplier_payments_view',
        ])}
      />
      <Route
        path="/supplier/payment/:page_number"
        element={requireAuth(SupplierPaymentFilesLazy, [
          'supplier_payments_view',
        ])}
      />
      <Route
        path="/international-supplier/payment"
        element={requireAuth(InternationalSupplierPaymentFilesLazy, [
          'supplier_payments_view',
        ])}
      />
      <Route
        path="/international-supplier/payment/:page_number"
        element={requireAuth(InternationalSupplierPaymentFilesLazy, [
          'supplier_payments_view',
        ])}
      />
      <Route
        path="/app-banners"
        element={requireAuth(BannersViewLazy, ['app_banners_view'])}
      />
      <Route
        path="/banner/add"
        element={requireAuth(BannerAddEditLazy, ['app_banner_add'])}
      />
      <Route
        path="/banner/:id"
        element={requireAuth(BannerAddEditLazy, ['app_banner_edit'])}
      />
      {/*<Route
        path="/dailytrends"
        element={requireAuth(TrendsViewLazy, ['trends_view'])}
      />*/}
      <Route
        path="/supply-tasks"
        element={requireAuth(SupplyTasksLazy, ['supply_tasks'])}
      />
      <Route
        path="/supply-tasks/catalog-hidden"
        element={requireAuth(PriceDeactivationLazy, ['supply_task_price_deactivation'])}
      />
      <Route
        path="/supply-tasks/move-catalog-to-collection-hidden"
        element={requireAuth(MoveCatalogToCollectionHiddenLazy, [
          'supply_task_move_catalog_to_collection_hidden',
        ])}
      />
      <Route
        path="/supply-tasks/move-catalogs-to-quality-hidden-collection"
        element={requireAuth(MoveCatalogToCollectionQualityHiddenLazy, [
          'move_catalogs_to_quality_hidden_collection',
        ])}
      />
      <Route
        path="/supply-tasks/add-catalogs-to-collections"
        element={requireAuth(AddCatalogsToCollectionsLazy, ['supply_task_add_catalogs_to_collections'])}
      />
      <Route
        path="/supply-tasks/remove-catalogs-from-collections"
        element={requireAuth(RemoveCatalogsFromCollectionsLazy, [
          'supply_task_remove_catalogs_to_collections',
        ])}
      />
      <Route
        path="/supply-tasks/suborder-sla-update"
        element={requireAuth(SuborderSLAUpdateLazy, ['suborder_sla_update'])}
      />
      <Route
        path="/supply-tasks/suborder-cancel-date-update"
        element={requireAuth(SubOrderCancelDateUpdateLazy, [
          'suborder_cancel_date_update',
        ])}
      />
      <Route
        path="/review-ratings"
        element={requireAuth(ReviewRatingsLazy, ['catalog_rating_review'])}
      />
      <Route
        path="/review-ratings/videos"
        element={requireAuth(ReviewRatingsVideosLazy, [
          'catalog_rating_review',
        ])}
      />
      <Route
        path="/review-ratings/videos/:page_number"
        element={requireAuth(ReviewRatingsVideosLazy, [
          'catalog_rating_review',
        ])}
      />
      <Route
        path="/review-ratings/:page_number"
        element={requireAuth(ReviewRatingsLazy, ['catalog_rating_review'])}
      />
      <Route
        path="/video-influencer"
        element={requireAuth(VideoInfluencerLazy, ['video_influencer_review'])}
      />
      <Route
        path="/dynamic-webview"
        element={requireAuth(DynamicWebViewLazy, ['dynamic_webview'])}
      />
      <Route
        path="/dynamic-webview/editor/:pageId"
        element={requireAuth(DynamicWebViewNewPageLazy, [
          'dynamic_webview_editor',
        ])}
      />
      <Route
        path="/firebase/admin"
        element={requireAuth(FirebaseAdminViewLazy, ['firebase_admin'])}
      />
      <Route
        path="/admin-access"
        element={requireAuth(AdminAccessViewLazy, ['user_access_admin'])}
      />
      <Route
        path="/catalog-rating-rewards"
        element={requireAuth(CatalogRatingRewardsViewLazy, [
          'catalog_rating_file_upload',
        ])}
      />
      <Route
        path="/catalog-rating-rewards/files"
        element={requireAuth(CatalogRatingRewardsFilesLazy, [
          'catalog_rating_file_upload',
        ])}
      />
      <Route
        path="/catalog-rating-rewards/file/:id"
        element={requireAuth(CatalogRatingRewardsFileLazy, [
          'catalog_rating_file_upload',
        ])}
      />
      <Route
        path="/meesho-rewards"
        element={requireAuth(ResellerTieringLazy, ['meesho_reseller_rewards'])}
      />
      <Route
        path="/meesho-rewards/files"
        element={requireAuth(ResellerTieringFilesLazy, [
          'meesho_reseller_rewards',
        ])}
      />
      <Route
        path="/meesho-rewards/file/:id"
        element={requireAuth(ResellerTieringFileLazy, [
          'meesho_reseller_rewards',
        ])}
      />
      <Route
        path="/mentor-dashboard"
        element={requireAuth(MentorDashboardLazy, [
          'mentor_performance_dashboard',
        ])}
      />
      <Route
        path="/mentor-dashboard/files"
        element={requireAuth(MentorDashboardFilesLazy, [
          'mentor_performance_dashboard',
        ])}
      />
      <Route
        path="/mentor-dashboard/file/:id"
        element={requireAuth(MentorDashboardFileLazy, [
          'mentor_performance_dashboard',
        ])}
      />
      {/* <Route path='/supplier-inventory-update'
          element={requireAuth(SupplierInventoryUpdateDashboardLazy, ['supplier_inventory_update'])}/>
    <Route path='/supplier-inventory-update/files'
          element={requireAuth(SupplierInventoryUpdateDashboardFilesLazy, ['supplier_inventory_update'])}/>
    <Route path='/supplier-inventory-update/file/:id'
          element={requireAuth(SupplierInventoryUpdateDashboardFileLazy, ['supplier_inventory_update'])}/> */}
      <Route
        path="/supplier-manifest"
        element={requireAuth(SupplierManifestViewLazy, ['supplier_manifest'])}
      />
      <Route
        path="/files-uploading"
        element={requireAuth(AdminFilesUploadingViewLazy, ['files_uploading'])}
      />
      <Route
        path="/china-vsku-upload"
        element={requireAuth(ChinaVSKUFileUploadLazy, [
          'china_vsku_file_upload',
        ])}
      />
      <Route
        path="/china-vsku-upload/files"
        element={requireAuth(ChinaVSKUUploadedFilesLazy, [
          'china_vsku_file_upload',
        ])}
      />
      <Route
        path="/china-vsku-upload/file/:id"
        element={requireAuth(ChinaVSKUUploadedFileLazy, [
          'china_vsku_file_upload',
        ])}
      />
      <Route
        path="/reverse-tinder"
        element={requireAuth(ReverseTinderFileUploadLazy, ['reverse_tinder'])}
      />
      <Route
        path="/reverse-tinder/files"
        element={requireAuth(ReverseTinderFilesLazy, ['reverse_tinder'])}
      />
      <Route
        path="/reverse-tinder/file/:id"
        element={requireAuth(ReverseTinderFileLazy, ['reverse_tinder'])}
      />
      <Route
        path="/visual-search"
        element={requireAuth(VisualSearchLazy, ['visual_search'])}
      />
      <Route
        path="/catalog-videos"
        element={requireAuth(CatalogVideosFileUploadLazy, [
          'catalog_videos_upload',
        ])}
      />
      <Route
        path="/catalog-videos/fetch"
        element={requireAuth(CatalogVideosFetchViewLazy, [
          'catalog_videos_upload',
        ])}
      />
      <Route
        path="/catalog-videos/files"
        element={requireAuth(CatalogVideosFilesLazy, ['catalog_videos_upload'])}
      />
      <Route
        path="/catalog-videos/file/:id"
        element={requireAuth(CatalogVideosFileLazy, ['catalog_videos_upload'])}
      />
      {/* <Route path='/product-supplier-price-update'
          element={requireAuth(ProductSupplierFileUploadLazy, ['product_supplier_price_update'])}/>
    <Route path='/product-supplier-price-update/files'
          element={requireAuth(ProductSupplierFilesLazy, ['product_supplier_price_update'])}/>
    <Route path='/product-supplier-price-update/file/:id'
          element={requireAuth(ProductSupplierFileLazy, ['product_supplier_price_update'])}/> */}
      <Route
        path="/marketing-dashboard"
        element={requireAuth(MarketingDashboardLazy, ['marketing_dashboard'])}
      />
      <Route
        path="/marketing-dashboard/bitly-generator"
        element={requireAuth(BitlyGeneratorLazy, ['marketing_bitly'])}
      />
      <Route
        path="/marketing-dashboard/url-shortener"
        element={requireAuth(UrlShortenerLazy, ['marketing_url_shorten'])}
      />
      <Route
        path="/marketing-dashboard/custom-url-shortener"
        element={requireAuth(CustomUrlShortenerLazy, ['marketing_url_shorten'])}
      />
      <Route
        path="/s3-file-uploader"
        element={requireAuth(FileUploaderLazy, ['s3_file_uploader'])}
      />
      <Route
        path="/taxonomy-product-file-upload"
        element={requireAuth(TaxonomyProductFileUploadLazy, [
          'taxonomy_product_file_upload',
        ])}
      />
      <Route
        path="/taxonomy-product-file-upload/files"
        element={requireAuth(TaxonomyProductFilesLazy, [
          'taxonomy_product_file_upload',
        ])}
      />
      <Route
        path="/taxonomy-product-file-upload/file/:id"
        element={requireAuth(TaxonomyProductFileLazy, [
          'taxonomy_product_file_upload',
        ])}
      />
      {/* <Route path='/tinder-taxonomy-product-file-upload'
          element={requireAuth(TinderTaxonomyProductFileUploadLazy, ['taxonomy_product_file_upload'])}/>
    <Route path='/tinder-taxonomy-product-file-upload/files'
          element={requireAuth(TinderTaxonomyProductFilesLazy, ['taxonomy_product_file_upload'])}/>
    <Route path='/tinder-taxonomy-product-file-upload/file/:id'
          element={requireAuth(TinderTaxonomyProductFileLazy, ['taxonomy_product_file_upload'])}/> */}
      {/* <Route path='/catalog-product-map'
          element={requireAuth(CatalogProductMapFileUploadLazy, ['catalog_product_map'])}/>
    <Route path='/catalog-product-map/files'
          element={requireAuth(CatalogProductMapFilesLazy, ['catalog_product_map'])}/>
    <Route path='/catalog-product-map/file/:id'
          element={requireAuth(CatalogProductMapFileLazy, ['catalog_product_map'])}/> */}
      <Route
        path="/product-delete"
        element={requireAuth(ProductsDeleteFileUploadLazy, [
          'product_delete_file_upload',
        ])}
      />
      <Route
        path="/product-delete/files"
        element={requireAuth(ProductsDeleteFilesLazy, [
          'product_delete_file_upload',
        ])}
      />
      <Route
        path="/product-delete/file/:id"
        element={requireAuth(ProductsDeleteFileLazy, [
          'product_delete_file_upload',
        ])}
      />
      <Route
        path="/ab-experiment"
        element={requireAuth(PriceExperimentViewLazy, ['ab_experiment'])}
      />
      <Route
        path="/ab-experiment/add"
        element={requireAuth(PriceExperimentAddEditLazy, [
          'ab_experiment_add',
          'ab_experiment_edit',
        ])}
      />
      <Route
        path="/ab-experiment/:id"
        element={requireAuth(PriceExperimentAddEditLazy, [
          'ab_experiment_edit',
        ])}
      />
      <Route
        path="/audience-system"
        element={requireAuth(AudienceSystemLazy, ['audience_system'])}
      />
      <Route
        path="/audience-system/add-manual"
        element={requireAuth(AudienceAddEditManualLazy, ['audience_system_add'])}
      />
      <Route
        path="/audience-system/edit-manual/:id"
        element={requireAuth(AudienceAddEditManualLazy, ['audience_system'])}
      />
      <Route
        path="/audience-system/add-automated"
        element={requireAuth(AudienceAddEditAutomatedLazy, ['audience_system_add'])}
      />
      <Route
        path="/audience-system/edit-automated/:id"
        element={requireAuth(AudienceAddEditAutomatedLazy, ['audience_system'])}
      />
      <Route
        path="/supplier-penalty"
        element={requireAuth(SupplierPenaltyFileUploadLazy, [
          'supplier_penalty_file_upload',
        ])}
      />
      <Route
        path="/supplier-penalty/files"
        element={requireAuth(SupplierPenaltyFilesLazy, [
          'supplier_penalty_file_upload',
        ])}
      />
      <Route
        path="/supplier-penalty/file/:id"
        element={requireAuth(SupplierPenaltyFileLazy, [
          'supplier_penalty_file_upload',
        ])}
      />
      <Route
        path="/supplier-penalty-waiver"
        element={requireAuth(SupplierPenaltyWaiverFileUploadLazy, [
          'supplier_penalty_waiver_file_upload',
        ])}
      />
      <Route
        path="/supplier-penalty-waiver/files"
        element={requireAuth(SupplierPenaltyWaiverFilesLazy, [
          'supplier_penalty_waiver_file_upload',
        ])}
      />
      <Route
        path="/supplier-penalty-waiver/file/:id"
        element={requireAuth(SupplierPenaltyWaiverFileLazy, [
          'supplier_penalty_waiver_file_upload',
        ])}
      />
      <Route
        path="/order-shipped-delivered"
        element={requireAuth(OrderShippedDeliveredUploadLazy, [
          'order_shipped_cancelled_file_upload',
        ])}
      />
      <Route
        path="/order-shipped-delivered/files"
        element={requireAuth(OrderShippedDeliveredFilesLazy, [
          'order_shipped_cancelled_file_upload',
        ])}
      />
      <Route
        path="/order-shipped-delivered/file/:id"
        element={requireAuth(OrderShippedDeliveredFileLazy, [
          'order_shipped_cancelled_file_upload',
        ])}
      />
      <Route
        path="/user-cod-file-upload"
        element={requireAuth(UserCODFileUploadLazy, [
          'user_cod_block_file_upload',
        ])}
      />
      <Route
        path="/user-cod-file-upload/files"
        element={requireAuth(UserCODUploadedFilesLazy, [
          'user_cod_block_file_upload',
        ])}
      />
      <Route
        path="/user-cod-file-upload/file/:id"
        element={requireAuth(UserCODFileLazy, ['user_cod_block_file_upload'])}
      />
      <Route
        path="/sms-portal"
        element={requireAuth(SmsPortalLazy, ['sms_portal_view'])}
      />
      <Route
        path="/sms-portal/create-template"
        element={requireAuth(SmsPortalCreateTemplateLazy, ['sms_portal_view'])}
      />
      <Route
        path="/sms-portal/send-sms"
        element={requireAuth(SmsPortalSendSmsLazy, ['sms_portal_view'])}
      />
      <Route
        path="/make-calls"
        element={requireAuth(SmsPortalMakeCallsLazy, ['make_calls_view'])}
      />
      <Route
        path="/notification-portal"
        element={requireAuth(NotificationPortalLazy, [
          'notification_portal_view',
        ])}
      />
      <Route
        path="/notification-portal/create-template"
        element={requireAuth(CreateTemplateLazy, ['notification_portal_view'])}
      />
      <Route
        path="/notification-portal/send-notification"
        element={requireAuth(SendNotificationsLazy, [
          'notification_portal_view',
        ])}
      />
      <Route
        path="/whatsapp-notification"
        element={requireAuth(WhatsAppNotificationsLazy, [
          'whatsapp_notifications_view',
        ])}
      />
      <Route
        path="/whatsapp-notification/create-template"
        element={requireAuth(WhatsAppCreateTemplateLazy, [
          'whatsapp_notifications_view',
        ])}
      />
      <Route
        path="/whatsapp-notification/send-notification"
        element={requireAuth(WhatsAppSendNotificationsLazy, [
          'whatsapp_notifications_view',
        ])}
      />
      <Route
        path="/all-notification-campaigns"
        element={requireAuth(AllNotificationCampaignsLazy, [
          'notification_all_campaigns',
        ])}
      />
      <Route
        path="/referral_campaign"
        element={requireAuth(ReferralCampaignLazy, ['referral_campaign'])}
      />
      <Route
        path="/referral_campaign/create"
        element={requireAuth(CreateCampaignLazy, ['referral_campaign'])}
      />
      <Route
        path="/referral_campaign/edit/:id"
        element={requireAuth(CreateCampaignLazy, ['referral_campaign'])}
      />
      <Route
        path="/referral_campaign/rewards"
        element={requireAuth(RewardCampaignLazy, ['referral_campaign'])}
      />
      <Route
        path="/referral_campaign/all_campaigns/:page_number"
        element={requireAuth(AllCampaignsLazy, ['referral_campaign'])}
      />
      <Route
        path="/referral_program"
        element={requireAuth(ReferralProgramLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/create_plan"
        element={requireAuth(CreateReferralPlanLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/edit_plan/:id"
        element={requireAuth(CreateReferralPlanLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/all_referral_plans/:page_number"
        element={requireAuth(AllReferralPlansLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/create_badge_group"
        element={requireAuth(BadgeGroupLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/edit_badge_group/:id"
        element={requireAuth(BadgeGroupLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/all_badge_groups/:page_number"
        element={requireAuth(AllBadgeGroupsLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/audience_system"
        element={requireAuth(ReferralAudienceSystemLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/audience_create"
        element={requireAuth(ReferralAudienceCreateLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/audience/:page_number"
        element={requireAuth(ReferralAudienceLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/audience_badge_map"
        element={requireAuth(AudienceBadgeMapLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/audience_badge_map/:page_number"
        element={requireAuth(AllAudienceBadgeMapLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/bulk_badge_group_update"
        element={requireAuth(BulkBadgeGroupUpdateLazy, ['referral_program'])}
      />
      <Route
        path="/referral_program/referral_approvals/:page_number"
        element={requireAuth(ReferralApprovalsLazy, ['referral_program'])}
      />
      <Route
        path="/user-block-file-upload"
        element={requireAuth(UserBlockFileUploadLazy, [
          'user_block_file_upload',
        ])}
      />
      <Route
        path="/user-block-file-upload/files"
        element={requireAuth(UserBlockUploadedFilesLazy, [
          'user_block_file_upload',
        ])}
      />
      <Route
        path="/user-block-file-upload/file/:id"
        element={requireAuth(UserBlockUploadedFileLazy, [
          'user_block_file_upload',
        ])}
      />
      {/* <Route path='/product-activate-deactivate-file-upload'
          element={requireAuth(ActivateDeactivateFileUploadLazy, ['product_activate_deactivate_file_upload'])}/>
    <Route path='/product-activate-deactivate-file-upload/files'
          element={requireAuth(ActivateDeactivateUploadedFilesLazy, ['product_activate_deactivate_file_upload'])}/>
    <Route path='/product-activate-deactivate-file-upload/file/:id'
          element={requireAuth(ActivateDeactivateUploadedFileLazy, ['product_activate_deactivate_file_upload'])}/> */}
      <Route
        path="/app-banner-file-upload"
        element={requireAuth(AppBannerFileUploadLazy, ['upload_banner'])}
      />
      <Route
        path="app-banner-file-upload/files"
        element={requireAuth(AppBannerFilesLazy, ['upload_banner'])}
      />
      <Route
        path="/app-banner/file/:id"
        element={requireAuth(AppBannerFileLazy, ['upload_banner'])}
      />
      <Route
        path="/supplier-penalty-type"
        element={requireAuth(SupplierPenaltyTypeFileUploadLazy, [
          'supplier_penalty_type_file_upload',
        ])}
      />
      <Route
        path="/supplier-penalty-type/files"
        element={requireAuth(SupplierPenaltyTypeFilesLazy, [
          'supplier_penalty_type_file_upload',
        ])}
      />
      <Route
        path="/supplier-penalty-type/file/:id"
        element={requireAuth(SupplierPenaltyTypeFileLazy, [
          'supplier_penalty_type_file_upload',
        ])}
      />
      <Route
        path="/add-attributes-view"
        element={requireAuth(SupplierAddAttributesLazy, [
          'add_attributes_view',
        ])}
      />
      <Route
        path="/sub-order-carrier-change"
        element={requireAuth(SubOrderCarrierChangeFileUploadLazy, [
          'sub_order_carrier_change',
        ])}
      />
      <Route
        path="/sub-order-carrier-change/files"
        element={requireAuth(SubOrderCarrierChangeFilesLazy, [
          'sub_order_carrier_change',
        ])}
      />
      <Route
        path="/sub-order-carrier-change/file/:id"
        element={requireAuth(SubOrderCarrierChangeFileLazy, [
          'sub_order_carrier_change',
        ])}
      />
      <Route
        path="/image-ab-file-upload"
        element={requireAuth(ImageABFileUploadLazy, ['image_ab_file_upload'])}
      />
      <Route
        path="/image-ab-file-upload/files"
        element={requireAuth(ImageABUploadedFilesLazy, [
          'image_ab_file_upload',
        ])}
      />
      <Route
        path="/image-ab-file-upload/file/:id"
        element={requireAuth(ImageABUploadedFileLazy, ['image_ab_file_upload'])}
      />
      <Route
        path="/bulk-collection-file-upload"
        element={requireAuth(BulkCollectionFileUploadLazy, [
          'bulk_collection_file_upload',
        ])}
      />
      <Route
        path="/bulk-collection-file-upload/files"
        element={requireAuth(BulkCollectionUploadedFilesLazy, [
          'bulk_collection_file_upload',
        ])}
      />
      <Route
        path="/bulk-collection-file-upload/file/:id"
        element={requireAuth(BulkCollectionUploadedFileLazy, [
          'bulk_collection_file_upload',
        ])}
      />
      <Route
        path="/excel-upload"
        element={requireAuth(ExcelUploadContainerLazy, [
          'generic_excel_upload',
        ])}
      >
        <Route
          path="files"
          element={requireAuth(ExcelUploadedFilesContainerLazy, [
            'generic_excel_upload',
          ])}
        />
      </Route>
      <Route
        path="/bulk-order"
        element={requireAuth(BulkOrderFileUploadLazy, [
          'bulk_ordering_file_upload',
        ])}
      />
      <Route
        path="/bulk-order/files"
        element={requireAuth(BulkOrderUploadedFilesLazy, [
          'bulk_ordering_file_upload',
        ])}
      />
      <Route
        path="/bulk-order/file/:id"
        element={requireAuth(BulkOrderUploadedFileLazy, [
          'bulk_ordering_file_upload',
        ])}
      />
      <Route
        path="/rule-engine"
        element={requireAuth(RuleEngineLazy, ['rule_engine'])}
      />
      <Route
        path="/rule-engine/create/:ruleId"
        element={requireAuth(RuleEngineCreateLazy, ['rule_engine'])}
      />
      <Route
        path="/order-status-file-upload"
        element={requireAuth(OrderStatusFileUploadLazy, [
          'order_status_file_upload',
        ])}
      />
      <Route
        path="/order-status-file-upload/files"
        element={requireAuth(OrderStatusFilesLazy, [
          'order_status_file_upload',
        ])}
      />
      <Route
        path="/order-status-file-upload/file/:id"
        element={requireAuth(OrderStatusFileLazy, ['order_status_file_upload'])}
      />
      <Route
        path="/order-ship-file-upload"
        element={requireAuth(OrderShipFileUploadLazy, [
          'order_ship_file_upload',
        ])}
      />
      <Route
        path="/order-ship-file-upload/files"
        element={requireAuth(OrderShipFilesLazy, ['order_ship_file_upload'])}
      />
      <Route
        path="/order-ship-file-upload/file/:id"
        element={requireAuth(OrderShipFileLazy, ['order_ship_file_upload'])}
      />
      <Route
        path="/search-ops"
        element={requireAuth(SearchOpsLazy, ['search_ops'])}
      />
      <Route
        path="/search-ops/abg-mapping"
        element={requireAuth(ABGMappingLazy, ['search_ops_intent_rule'])}
      />
      <Route
        path="/search-ops/intent-detection-rule"
        element={requireAuth(IntentDetectionRulesLazy, [
          'search_ops_intent_rule',
        ])}
      />
      <Route
        path="/search-ops/intent-detection-rule/add"
        element={requireAuth(AddIntentDetectionRuleLazy, [
          'search_ops_intent_rule_add',
        ])}
      />
      <Route
        path="/search-ops/intent-detection-rule/:id"
        element={requireAuth(AddIntentDetectionRuleLazy, [
          'search_ops_intent_rule_edit',
        ])}
      />
      <Route
        path="/v2/search-ops/intent-detection-rule"
        element={requireAuth(IntentDetectionRulesV2Lazy, [
          'search_ops_intent_rule',
        ])}
      />
      <Route
        path="/v2/search-ops/intent-detection-rule/add"
        element={requireAuth(AddIntentDetectionRuleV2Lazy, [
          'search_ops_intent_rule_add',
        ])}
      />
      <Route
        path="/v2/search-ops/intent-detection-rule/:id"
        element={requireAuth(AddIntentDetectionRuleV2Lazy, [
          'search_ops_intent_rule_add',
        ])}
      />

      <Route
        path="/search-ops/search-indexing-rule"
        element={requireAuth(SearchRuleEngineLazy, ['search_rule_engine'])}
      />
      <Route
        path="/search-ops/search-indexing-rule/add"
        element={requireAuth(AddSearchRuleLazy, ['search_rule_engine_add'])}
      />
      <Route
        path="/search-ops/search-indexing-rule/:refType/:id"
        element={requireAuth(AddSearchRuleLazy, ['search_rule_engine_edit'])}
      />
      <Route
        path="/search-ops/query-normalization"
        element={requireAuth(QueryNormalizationLazy, ['query_normalization'])}
      />
      <Route
        path="/search-ops/query-normalization/add"
        element={requireAuth(AddEditQueryNormalizationLazy, [
          'query_normalization_add_edit',
        ])}
      />
      <Route
        path="/search-ops/query-normalization/:id"
        element={requireAuth(AddEditQueryNormalizationLazy, [
          'query_normalization_add_edit',
        ])}
      />
      <Route
        path="/search-ops/query-expansion"
        element={requireAuth(QueryExpansionLazy, ['query_expansion'])}
      />
      <Route
        path="/search-ops/query-tagging"
        element={requireAuth(QueryTaggingLazy, ['query_tagging'])}
      />
      <Route
        path="/search-ops/query-tagging/add/rsvt"
        element={requireAuth(AddEditQueryTaggingLazy, ['query_tagging'])}
      />
      <Route
        path="/search-ops/query-tagging/add/blacklist"
        element={requireAuth(AddEditBlacklistLazy, ['query_tagging_add_edit'])}
      />
      <Route
        path="/search-ops/query-tagging/:id/blacklist"
        element={requireAuth(AddEditBlacklistLazy, ['query_tagging_add_edit'])}
      />
      <Route
        path="/search-ops/query-tagging/:id/:type"
        element={requireAuth(AddEditQueryTaggingLazy, ['query_tagging'])}
      />
      <Route
        path="/search-ops/query-tagging/blacklist"
        element={requireAuth(QueryTaggingBlacklistLazy, [
          'query_tagging_blacklist',
        ])}
      />
      <Route
        path="/query-tagging-file-upload/blacklist"
        element={requireAuth(QueryTaggingBlacklistFileUploadLazy, [
          'query_tagging_bulk_upload',
        ])}
      />
      <Route
        path="/query-tagging-file-upload/rsvt"
        element={requireAuth(QueryTaggingFileUploadLazy, ['query_tagging'])}
      />
      <Route
        path="/search-ops/qcl-correction"
        element={requireAuth(QclCorrectionLazy, ['qcl_correction'])}
      />
      <Route
        path="/search-ops/stop-words"
        element={requireAuth(StopWordsLazy, ['search_stop_words'])}
      />
      <Route
        path="/images-bulk-upload"
        element={requireAuth(ImagesBulkUploadLazy, [
          'admin_images_bulk_upload',
        ])}
      />
      <Route
        path="/query-normalization-file-upload"
        element={requireAuth(QueryNormalizationFileUploadLazy, [
          'query_normalization_bulk_upload',
        ])}
      />
      <Route
        path="/search-ops/review"
        element={requireAuth(SearchReviewLazy, ['search_engine_review'])}
      />
      <Route
        path="/category-navigation"
        element={requireAuth(CategoryTreesLazy, ['category_navigation_trees'])}
      />
      <Route
        path="/category-navigation/editor/:treeId"
        element={requireAuth(CategoryTreesEditorLazy, [
          'category_navigation_trees_editor',
        ])}
      />
      <Route
        path="/clp-admin"
        element={requireAuth(CLPAdminLazy, ['clp_admin'])}
      />
      <Route
        path="/top-nav-admin"
        element={requireAuth(TopNavAdminLazy, ['top_nav_admin'])}
      />
      <Route
        path="/sort-and-filter"
        element={requireAuth(SortnFilterLazy, ['sort_and_filter'])}
      />
      <Route
        path="/community/moderation"
        element={requireAuth(CommunityModerationPageLazy, [
          'community_moderation',
        ])}
      />
      <Route
        path="/pow-products-file-upload"
        element={requireAuth(POWProductFileUploadLazy, [
          'pow_products_file_upload',
        ])}
      />
      <Route
        path="/pow-products-file-upload/files"
        element={requireAuth(POWProductUploadedFilesLazy, [
          'pow_products_file_upload',
        ])}
      />
      <Route
        path="/pow-products-file-upload/file/:id"
        element={requireAuth(POWProductUploadedFileLazy, [
          'pow_products_file_upload',
        ])}
      />
      <Route
        path="/clp/products"
        element={requireAuth(CLPProductsViewLazy, ['clp_products_view'])}
      />
      <Route
        path="/community/upload"
        element={requireAuth(CommunityUploadPageLazy, [
          'community_upload',
        ])}
      />
      <Route
        path="/supply-tasks/uat-catalogs"
        element={requireAuth(UATCatalogsLazy, ['uat_catalogs'])}
      />
      <Route
        path="/hvf-admin"
        element={requireAuth(HVFAdminPageLazy, ['hvf_admin'])}
      />
      <Route
        path="/hvf-admin/add-new-filter-group"
        element={requireAuth(AddNewFilterGroupLazy, ['hvf_admin'])}
      />
      <Route
        path="/hvf-admin/edit-filter-group"
        element={requireAuth(EditFilterGroupLazy, ['hvf_admin'])}
      />
      {/*<Route
        path="/farmiso/dealer-activate"
        element={requireAuth(DealerActivateLazy, ['farmiso_dealer_activate'])}
      />*/}
      <Route
        path="/verify-kyc"
        element={requireAuth(SupplierFinanceLazy, ['supplier_finance'])}
      />
      <Route
        path="/fulfilment"
        element={requireAuth(FulfilmentLazy, ['return_reasons'])}
      />
      <Route
        path="/serviceability-file-upload"
        element={requireAuth(ServiceabilityFileUploadLazy, [
          'serviceability_file_upload',
        ])}
      />
      <Route
        path="/admin-notices"
        element={requireAuth(AdminNoticesLazy, ['admin_notice'])}
      />
      <Route
        path="/legal-and-policies"
        element={requireAuth(LegalAndPoliciesLazy, ['legal_and_policies'])}
      />
      <Route
        path="/legal-and-policies/policy/:policy_id"
        element={requireAuth(UpdateLegalAndPoliciesLazy, [
          'legal_and_policies',
        ])}
      />
      <Route
        path="/ads-task"
        element={requireAuth(AdsTaskLazy, ['supplier_ads_task'])}
      />
      <Route
        path="/ads-task/add"
        element={requireAuth(AdsTaskFileUploadLazy, ['supplier_ads_task'])}
      />
      <Route
        path="/mma-task"
        element={requireAuth(MMALinkLazy, ['supplier_ads_task_mma_deep_link'])}
      />
      <Route
        path="/mma-task/generate-mma-banner"
        element={requireAuth(MMAConsentBannerCreation, ['supplier_ads_task_mma_deep_link'])}
      />
      <Route
        path="/ads-task/:task/add"
        element={requireAuth(AdsTaskFileUploadLazy, ['supplier_ads_task'])}
      />
      <Route
        path="/ads-task/:task"
        element={requireAuth(TaskCampaignLazy, ['supplier_ads_task'])}
      />
      <Route
        path="/ads-task/:task/:id/status"
        element={requireAuth(TaskStatusLazy, ['supplier_ads_task'])}
      />
      <Route
        path="/live-commerce"
        element={requireAuth(LiveCommerceLazy, ['live_stream_view'])}
      />
      <Route
        path="/live-commerce-templates"
        element={requireAuth(TemplatePresetsLazy, [
          'live_stream_notiifcations',
        ])}
      />
      <Route
        path="/live-commerce-template/create-preset"
        element={requireAuth(CreateTemplatePresetLazy, [
          'live_stream_notiifcations',
        ])}
      />
      <Route
        path="/live-commerce-template/edit/:id"
        element={requireAuth(CreateTemplatePresetLazy, [
          'live_stream_notiifcations',
        ])}
      />
      <Route
        path="/live-commerce-campaigns"
        element={requireAuth(CampaignPresetsLazy, [
          'live_stream_notiifcations',
        ])}
      />
      <Route
        path="/live-commerce-campaign/edit/:id"
        element={requireAuth(CreateCampaignPresetLazy, [
          'live_stream_notiifcations',
        ])}
      />
      <Route
        path="/live-commerce-campaign/create-preset"
        element={requireAuth(CreateCampaignPresetLazy, [
          'live_stream_notiifcations',
        ])}
      />
      <Route
        path="/live-commerce-streams"
        element={requireAuth(LiveStreamsLazy, ['live_stream_view'])}
      />
      <Route
        path="/live-commerce-streams/create"
        element={requireAuth(CreateLiveStreamLazy, ['live_stream_create'])}
      />
      <Route
        path="/live-commerce-streams/upload"
        element={requireAuth(LiveStreamBulkStreamCreationLazy, ['live_stream_create'])}
      />
      <Route
        path="/live-commerce-streams/update"
        element={requireAuth(LiveStreamBulkStreamUpdationLazy, ['live_stream_create'])}
      />
      <Route
        path="/live-commerce-streams/:id"
        element={requireAuth(LiveStreamLazy, ['live_stream_view'])}
      />
      <Route
        path="/live-commerce-streams/edit/:id"
        element={requireAuth(CreateLiveStreamLazy, ['live_stream_edit'])}
      />
      <Route
        path="/live-commerce-real-estates"
        element={requireAuth(RealEstatePresetsLazy, [
          'live_stream_real_estates',
        ])}
      />
      <Route
        path="/live-commerce-real-estate/create"
        element={requireAuth(CreateRealEstatePresetLazy, [
          'live_stream_real_estates',
        ])}
      />
      <Route
        path="/live-commerce-real-estate/edit/:id"
        element={requireAuth(CreateRealEstatePresetLazy, [
          'live_stream_real_estates',
        ])}
      />
      <Route
        path="/live-commerce-banners"
        element={requireAuth(BannersLazy, ['live_stream_real_estates'])}
      />
      <Route
        path="/live-commerce-banner/create"
        element={requireAuth(UpsertBannersLazy, ['live_stream_real_estates'])}
      />
      <Route
        path="/live-commerce-banner/edit/:id"
        element={requireAuth(UpsertBannersLazy, ['live_stream_real_estates'])}
      />
      <Route
        path="/live-commerce-discounts"
        element={requireAuth(DiscountsLazy, ['live_stream_discounts'])}
      />
      <Route
        path="/live-commerce-discounts/add"
        element={requireAuth(AddDiscountLazy, ['live_stream_discounts'])}
      />
      <Route
        path="/live-commerce-discounts/upload"
        element={requireAuth(UploadDiscountLazy, ['live_stream_discounts'])}
      />
      <Route
        path="/live-commerce-discounts/:offerId"
        element={requireAuth(AddDiscountLazy, ['live_stream_discounts'])}
      />
      <Route path="/campaign-dashboard" element={requireAuth(CampaignDashboard,['dispaly_ads_section'])}/>
      <Route path="/display-awareness/:id" element={requireAuth(DisplayAwareness,['dispaly_ads_section'])}/>
      <Route path="/create-new-campaign" element={requireAuth(CreateNewCampaign,['dispaly_ads_section'])}/>
      <Route path="/non-seller-portal" element={requireAuth(NonSellerPortal,['dispaly_ads_section'])}/>
    </Routes>
  </React.Suspense>
);
